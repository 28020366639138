export default (
  formConfigSchema=null,formName=''
) => {
    return {
        props:{
            data:{
                type:Object,
                default:null
            },
            options: {
                type: Object,
                default: () => ({})

            },
        },
        data(){
            return {
                refreshFileUpload:false,
                buttonsForm:{
                    captionNo: "buttons.actions.close",
                    captionYes: "buttons.actions.save"
                }
            }
        },

        computed: {

            formConfig(){
                return formConfigSchema
            },

            initDataForm(){
                if (!this.data.init){
                    return this.data;
                }else{
                    return this.data.hasOwnProperty('init')?this.data.init:null;
                }
            }
        },

        methods: {

            closeForm() {
                this.$emit("onClose");
            },

            setFunctionDownloadImage(fieldName){
                this.formConfig.form.map((item)=>{
                    if(item.name===fieldName){
                        item.imagePreview=() => this.downloadImage(this.initDataForm[fieldName]);
                    };
                    return item;
                });
            },


            execEventComponent({action,data}) {
                switch (action) {
                    case 'refreshFileUpload':
                        this.refreshFileUpload = true;
                        break;
                    case 'another':
                        break;
                    default:
                }
            },


                saveDataForm(data) {
                if (this.data !== null) {

                    let idChild=(data.idChild)?data.idChild:data.id;

                    data = {
                        ...{
                            outputData: {
                                ...data, ...{
                                    idChild,
                                    entity: this.entity,
                                    entityChild: this.entityChild
                                }
                            }, ...{inputData: this.data}
                        }
                    };
                    data = {...data, ...{name: formName}};

                    this.$emit("onDataEvent", data);
                    this.closeForm();
                }
            }
        }




    };

}
