<template>
    <div style="min-width: 100%">
        <v-row>
            <slot name="filter"></slot>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                        v-model="selected"
                        :fixed-header="true"
                        :headers="headersVisible"
                        :items="data.items"
                        :sort-by="data.sortBy"
                        :sort-desc="data.sortDesc"
                        :single-select="singleSelect"
                        :show-select="showMultipleSelect"
                        :item-key="data.items.id"
                        :hide-default-footer="showCustomFooterPagination"
                        multi-sort
                        class="elevation-1"
                >

                    <template v-if="showSwitchSingleSelect" v-slot:top>
                        <v-switch
                                v-model="singleSelect"
                                label="Single select"
                                class="pa-3"
                        ></v-switch>
                    </template>

                    <template v-slot:item.tags="{ item }">

                        <v-chip v-for="(tag, index) in item.tags" :key="index"
                                color="primary"
                                dark
                        >
                            {{ tag }}
                        </v-chip>
                    </template>


                    <template v-slot:item.actions="{ item }">
                        <actions-table  @onClickAction="clickAction" :item="item"/>
                    </template>
                </v-data-table>
                <v-card v-if="showCustomFooterPagination" class="customFooterPagination">
                    <v-card-actions class="actionsDetails" style="background: rgb(249, 249, 249); border-top-left-radius: 0; border-top-right-radius: 0">
                        <v-spacer></v-spacer>
                        <slot name="footer"></slot>
                    </v-card-actions>
                </v-card>


            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ActionsTable from "./ActionsTable"
    export default {
        name: "Table",
        components:{
            ActionsTable
        },

        props:{
            data:{
                type:Object,
                default: () => ({})
            },
            showCustomFooterPagination:{
              type:Boolean,
              default:{
                  default: false
              }
            },
            showMultipleSelect:{
                type:Boolean,
                default: false,
            },
            showSwitchSingleSelect:{
                type:Boolean,
                default: false,
            }
        },

        data(){

            return {
                singleSelect: false,
                selected: [],
                headersVisible:[],
                itemsVisible:[]
            }

        },

        watch:{
            selected(newSelected,oldSelected){
                this.clickAction(newSelected,'onSelectedRows');
            }

        },

        created(){
            let headersHidden=this.data.headers.filter(function( obj ) {
                return ((obj.hasOwnProperty('hidden')) && obj.hidden);
            });

            this.headersVisible=this.data.headers.filter(function( obj ) {
                return ((!obj.hasOwnProperty('hidden')) && !obj.hidden);
            });

            this.data.items.forEach((v)=>{

                headersHidden.forEach((i)=>{
                    delete v[i.value]
                });



            });


        },

        computed:{

            headers(){
                return this.data.headers;
            },

            items(){
                return this.data.items;
            }

        },


        methods:{

            clickAction(item,event){
                this.$emit('onDataEvent',{item,event});
            },


        }

    }
</script>

<style lang="scss">
    .customFooterPagination{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow:
                0px 5px 1px -2px rgba(0, 0, 0, 0.2),
                0px 5px 2px 0px rgba(0, 0, 0, 0.14),
                0px 5px 5px 0px rgba(0, 0, 0, 0.12);
    }
</style>
