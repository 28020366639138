<template>
    <v-container class="my-1" fluid>
        <header-view :title="$t('word.storefront')"/>
        <store-front-actions :storeFrontModel="storeFrontModel"/>
        <v-row>
            <v-col>
                <finder-custom
                        :onlyDropChildren="true"
                        :entitiesCaption="entitiesCaption"
                        v-if="storeFrontModel.tree"
                        :tree="storeFrontModel.tree"
                        @move="onMove"
                        @updateChildren="updateChildren"
                        :externalInput="externalInput"
                        :dragEnabled="true"
                        @actionList="actionList"
                        ref="myFinder">
                </finder-custom>

                <dialogEntity @onDataEvent="saveDataEntity" :paramsDialog="{
                            fullscreen:false}">
                </dialogEntity>
            </v-col>
        </v-row>

        <confirm-dialog-vuetify ref="confirm"/>

    </v-container>

</template>

<script>

    import StoreFrontActions from "../components/storeFront/storeFrontActions";
    const DIALOG_ENTITIES={

        shelf:{
            component:"storeFront/entities/shelf/ShelfForm",
            options:{maxWidth:'600px'}
        },
        group:{
            component:"storeFront/entities/group/GroupForm",
            options:{maxWidth:'600px'}
        },
        video:{
            component:"storeFront/entities/videoClip/VideoClipForm",
            options:{maxWidth:'1200px'}
        },
    };

    import StoreFrontModel from "@/components/storeFront/storeFrontModel";
    import DialogEntity from "@/components/dialog/FormDialog"
    import StorefrontRepository from "@/repositories/storefrontRepository"
    import HeaderView from "@/components/layout/HeaderView";
import { showMessage } from '@/helpers/messages';

    export default {
        components: {
            StoreFrontActions,
            HeaderView,
            DialogEntity},
        data() {
            return {
                externalInput:{},
                dialogsEntities:DIALOG_ENTITIES,
                lastNodeSelected:null,
                storeFrontModel: new StoreFrontModel([]),
                itemComponentTemplate:{

                    props:["item"],
                      template:

                        "<p><b>{{item.label}}</b></p><p>{{item.contentType}}</p><p>{{item.id}}</p>"

                },
                entitiesCaption:{
                    "shelf":"Shelf",
                    "group":"Groups",
                    "video":"Items"
                }

            }
        },

        async created(){
            StorefrontRepository.observe("staging",
                {
                    next: async (storeFront) => {
                        console.log("Model:")
                        console.log(this.storeFrontModel)
                        if (!this.storeFrontModel.dirty) {
                            this.storeFrontModel=new StoreFrontModel(storeFront);
                            this.storeFrontModel.init();
                        } else {
                            console.log("Hooray update!");
                            if (await this.$refs.confirm.open(
                                this.$t(`confirm.refresh.storefront.title`),
                                this.$t(`confirm.refresh.storefront.message`)
                                )) {
                                this.storeFrontModel=new StoreFrontModel(storeFront);
                                this.storeFrontModel.init();
                            }
                        } 
                    }
                })
            // this.storeFrontModel=new StoreFrontModel({
            //     "items": [
            //         {
            //             "title": "Abc",
            //             "contentType": "video_clips",
            //             "size": "small",
            //             "id": "fewfwefewf",
            //             "idChild":"fddsgfdg4343",
            //             "content": [
            //                 {
            //                     "idChild":"sfew322r34",
            //                     "id": "4yy654y65",
            //                     "legacyServerId":"wfwerfwf",
            //                     "name": "I pirati dei caraibi",
            //                     "verticalImageRef": "video_clips/a1231/vertical_img.jpg",
            //                     "tags": ["pirati", "caraibi"]
            //                 }
            //             ]
            //         },
            //         {
            //             "title": "Abc",
            //             "idChild":"43535453",
            //             "id": "fwefewwfefewfwef",
            //             "contentType": "video_clips",
            //             "size": "large",
            //             "content": [
            //                 {
            //                     "idChild":"grefw546t45",
            //                     "id": "treyertye65ey65",
            //                     "legacyServerId":"wfwerfwf",
            //                     "name": "I pirati dei caraibi",
            //                     "verticalImageRef": "video_clips/a1231/vertical_img.jpg",
            //                     "tags": ["pirati", "caraibi"]
            //                 },
            //                 {
            //                     "idChild":"fdthrth56dsgfdg4343",
            //                     "id": "4y45y45y345",
            //                     "legacyServerId":"wfwerfwf",
            //                     "name": "I pirati dei caraibi II",
            //                     "verticalImageRef": "video_clips/a1234/vertical_img.jpg",
            //                     "tags": ["pirati", "caraibi"]
            //                 }
            //             ]
            //         },
            //         {
            //             "title": "Abc",
            //             "id": "ccewr43r43r4",
            //             "idChild":"ytnjytyt",
            //             "contentType": "groups_with_photos",
            //             "content": [
            //                 {
            //                     "name": "Cinepanettoni 1982",
            //                     "idChild":"ytnjytysdvsdfvg",
            //                     "id": "fwrffr43fv45",
            //                     "imageRef": "groups/abcd-fefa-dsa-asda.jpg",
            //                     "content": [
            //                         {
            //                             "id": "y456yy45y4",
            //                             "idChild":"vdsvdsv4334",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Vacanze di Natale",
            //                             "verticalImageRef": "video_clips/b1231/vertical_img.jpg",
            //                             "tags": ["natale", "christian-de-sica"]
            //                         },
            //                         {
            //                             "id": "4y65y65y56y",
            //                             "idChild":"sacsa34ew334",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Vacanze di Natale a Cortina",
            //                             "verticalImageRef": "video_clips/b1234/vertical_img.jpg",
            //                             "tags": ["natale", "cortina", "christian-de-sica"]
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     "name": "Claudio Bisio",
            //                     "id": "vfdgveg54",
            //                     "idChild":"wefewfwef",
            //                     "imageRef": "groups/abcd-fefa-dsa-asda.jpg",
            //                     "content": [
            //                         {
            //                             "id": "th645h65hg6y",
            //                             "idChild":"wefewfewf",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Benvenuti al Sud",
            //                             "verticalImageRef": "video_clips/c1231/vertical_img.jpg",
            //                             "tags": ["sud", "benvenuti", "bisio"]
            //                         },
            //                         {
            //                             "id": "yhrty6565437y",
            //                             "idChild":"ewfewf4343",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Benvenuti al Nord",
            //                             "verticalImageRef": "video_clips/c1234/vertical_img.jpg",
            //                             "tags": ["benvenuti", "bisio"]
            //                         }
            //                     ]
            //                 }
            //             ]
            //         },
            //         {
            //             "title": "Abc2",
            //             "id": "dfcafcew2r432",
            //             "idChild":"wefewf434343",
            //             "contentType": "groups",
            //             "content": [
            //                 {
            //                     "name": "Cinepanettoni 2000",
            //                     "id": "dawsf432rr4",
            //                     "idChild":"ewwefwefew4343",
            //                     "content": [
            //                         {
            //                             "id": "45y456y645yyy",
            //                             "idChild":"gfgrt54565",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Vacanze di Natale 2000",
            //                             "verticalImageRef": "video_clips/b1231/vertical_img.jpg",
            //                             "tags": ["natale", "christian-de-sica"]
            //                         },
            //                         {
            //                             "id": "ytr5hy65",
            //                             "idChild":"43t454566765",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Vacanze di Natale a Cortina 2000",
            //                             "verticalImageRef": "video_clips/b1234/vertical_img.jpg",
            //                             "tags": ["natale", "cortina", "christian-de-sica"]
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     "name": "Claudio  2000",
            //                     "id": "ewfdre3wr7667",
            //                     "idChild":"43tt43gh98",
            //                     "content": [
            //                         {
            //                             "id": "456456gre",
            //                             "idChild":"t4554t465y78",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Benvenuti al Sud 2000",
            //                             "verticalImageRef": "video_clips/c1231/vertical_img.jpg",
            //                             "tags": ["sud", "benvenuti", "bisio"]
            //                         },
            //                         {
            //                             "id": "trhe565656",
            //                             "idChild":"trtertrty656565",
            //                             "legacyServerId":"wfwerfwf",
            //                             "name": "Benvenuti al Nord 2000",
            //                             "verticalImageRef": "video_clips/c1234/vertical_img.jpg",
            //                             "tags": ["benvenuti", "bisio"]
            //                         }
            //                     ]
            //                 }
            //             ]
            //         }
            //     ]
            // });

        },

        beforeMount() {
            window.addEventListener("beforeunload", this.preventNav)
            this.$once("hook:beforeDestroy", () => {
                window.removeEventListener("beforeunload", this.preventNav);
            })
        },

        async beforeRouteLeave(to, from, next) {
            if (this.storeFrontModel.dirty) {

                if (!await this.$refs.confirm.open(
                  this.$t("confirm.leave.storefront.title"),
                  this.$t("confirm.leave.storefront.message")
                )) {
                    return
                }
            }
            next();
        },

        methods:{

            preventNav(event) {
                if (!this.storeFrontModel.dirty) return
                event.preventDefault()
                event.returnValue = ""
            },

         //    scrollToElement(item) {
         //        const el = this.$el.getElementsByClassName(item)[0];
         //
         //        if (el) {
         //            // Use el.scrollIntoView() to instantly scroll to the element
         //            el.scrollIntoView({behavior: 'smooth'});
         //    }
         // } ,



            onMove({ moved, to, index }) {
                this.storeFrontModel.moveNode(moved,{id:to,pos:index},'children');
            },


            openDialogForm(action,parent,nodeSelected){
                let steps=[];
                let nameAction="add";
                let data={
                        action,
                        parent
                };


                if ((action==="update") && (nodeSelected)){
                    nameAction="update";
                    data={...data,...nodeSelected};
                }


                let options=this.dialogsEntities[parent.entityChild].options;
                let component=this.dialogsEntities[parent.entityChild].component;
                let title=this.$t(`dialog.storefront.title.${nameAction}_${parent.entityChild}`);

                    switch (parent.entityChild) {
                        case 'shelf':
                            steps=[{
                                title,
                                component,
                            }];
                            break;
                        case 'group':
                            steps=[{
                                title,
                                component,
                            }];
                            break;

                        case 'video':
                            steps=[{
                                title,
                                component,
                            }];
                            break;
                    }

                    let params={
                        options,
                        steps,
                        data
                    };


                    EventBus.emit("showFormDialog",{params,show:true});

            },

            async removeItems(data){

                if (await this.$refs.confirm.open(
                  this.$t(`confirm.delete.entity.${data.nodeSelected.entity}.title`),
                  this.$t(`confirm.delete.entity.${data.nodeSelected.entity}.message`,{name:data.nodeSelected.label})
                )) {this.externalInput=data}

            },


            actionList(data){


                if (data.action==="add" || data.action==="update"){
                    if (data.parent.contentType){
                        data.nodeSelected={...data.nodeSelected,...{parentContentType:data.parent.contentType}}
                    }
                    this.openDialogForm(data.action,data.parent,data.nodeSelected)
                }else{

                    this.removeItems(data);


                }
            },


            saveDataEntity(data){

                let idEntity=(data.inputData.action==="add")?data.outputData.id:data.inputData.id;
                let parentId=(data.inputData.action==="add")?data.inputData.parent.id:data.inputData.parent;

                if ((data.outputData.contentType) && (data.outputData.contentType.value)){
                    data.outputData.contentType=data.outputData.contentType.value;
                }

                if (data["outputData"].items){
                    data["outputData"].items.map(item=>{
                        item.parent=parentId;
                        return item;
                    });
                }else{
                    data["outputData"]={...data.outputData,...{id:idEntity,parent:parentId,parental:parentId}};
                }


               this.externalInput=data;
            },

            updateChildren({action,index,tree,parentId,nodeId}){
                this.storeFrontModel.updateChildren(action,index,tree,parentId,nodeId);
            },




        }
    };
</script>

<style lang="css">

    .tree-container .list-container[data-v-02ca9644] {
        display: flex;
        height: 100%;
    }

    /*# sourceMappingURL=Finder.vue.map */.list[data-v-26181b62] {
                                              display: flex;
                                              flex-direction: column;
                                              min-width: 250px;
                                              min-height: 100%;
                                              border-right: solid 1px #ccc;
                                              overflow: auto;
                                              flex-shrink: 0;
                                          }
    .list [draggable=true][data-v-26181b62] {
        cursor: move;
        cursor: grab;
    }
    .last[data-v-26181b62] {
        flex-grow: 1;
    }

    /*# sourceMappingURL=FinderList.vue.map */.item[data-v-b012b6c0] {
                                                  padding-right: 10px;
                                                  display: flex;
                                                  align-items: center;
                                                  position: relative;
                                                  border: dashed 3px transparent;
                                              }
    .item.expanded[data-v-b012b6c0] {
        background-color: #0b79d0;
        color: white;
    }
    .item.dragged[data-v-b012b6c0] {
        background-color: rgba(11, 121, 208, 0.5);
    }
    .item.drag-over[data-v-b012b6c0] {
        border-color: #0b79d0;
        background-color: rgba(11, 121, 208, 0.2);
    }
    .item.has-drag-handle .inner-item[data-v-b012b6c0] {
        padding-left: 0;
    }
    .item.no-drop[data-v-b012b6c0] {
        color: gray;
    }
    .item .inner-item[data-v-b012b6c0] {
        flex: 1;
        min-width: 0;
        padding: 10px;
    }
    .item[data-v-b012b6c0]:focus {
        outline: none;
    }
    .item .drag-handle[data-v-b012b6c0] {
        fill: #bbb;
        padding: 10px 1px 10px 0;
        width: 15px;
        height: 15px;
        cursor: move;
        cursor: grab;
    }

    /*# sourceMappingURL=FinderItem.vue.map */.drop-zone[data-v-b2b8c02e] {
                                                  transition: height 0.1s ease;
                                                  height: 0.5em;
                                                  flex-shrink: 0;
                                                  overflow: hidden;
                                                  display: flex;
                                                  flex-direction: column;
                                              }
    .drop-zone.drag-over[data-v-b2b8c02e] {
        opacity: 0.5;
        margin-bottom: 0;
        height: 2em;
        border: dashed 3px #0b79d0;
        background-color: rgba(11, 121, 208, 0.2);
    }
    .no-drop *[data-v-b2b8c02e] {
        cursor: no-drop;
    }

    /*# sourceMappingURL=FinderListDropZone.vue.map */.arrow[data-v-6c65f172] {
                                                          display: inline-block;
                                                          border-right: 3px solid black;
                                                          border-bottom: 3px solid black;
                                                          width: 6px;
                                                          height: 6px;
                                                          transform: rotate(-45deg);
                                                      }
    .arrow.expanded[data-v-6c65f172] {
        border-color: white;
    }

    /*# sourceMappingURL=FinderItemArrow.vue.map */

    .list{
        min-width: calc(28vw)!important;
        max-width: calc(28vw)!important;
        max-height: 65vh;
        overflow-y: scroll;
    }

     .item .inner-item {
         font-size: 12px;
     }

    .tree-container{
        overflow-x: auto;
        height: 100% !important;
    }

    .buttonsTop{
        position: absolute;
        top: 10px;
        right:10px;
    }

</style>
