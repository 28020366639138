import firebase from "firebase/compat/app";
import {showMessage} from '@/helpers/messages'
import Vue from 'vue'

const get = (storefrontId) => {
    return new Promise((resolve, reject) => {

    Vue.showLoadingOverlay(true);
    let collection = firebase.firestore()
        .collection("storefronts")
        .doc(storefrontId)
        .collection("items")
        .orderBy("position");
    
    collection.get()
        .then((result) => {
            // Read result of the Cloud Function.
            Vue.showLoadingOverlay(false);
            const mappedResult = result.docs.map( el => {
                return {
                    id: el.id,
                    ...el.data(),
                }
            });
            resolve({
                items: mappedResult
            });

        })
        .catch((error) => {
            // Getting the Error details.
            Vue.showLoadingOverlay(false);
            showMessage({
                text: error.message,
                type: "error"
            });
            reject({
                    code : error.code,
                    message : error.message,
                    details : error.details,
            })

        });
    });
};
/**
 * 
 * @param {string} storefrontId 
 * @param  {{
 *   next?: (list: any) => void;
 *   error?: (error: any) => void;
 * }} observer
 * @returns 
 */
const observe = (storefrontId, observer) => {
    Vue.showLoadingOverlay(true);
    let collection = firebase.firestore()
        .collection("storefronts")
        .doc(storefrontId)
        .collection("items")
        .orderBy("position");
    
    collection
        .onSnapshot((snapshot) => {
            Vue.showLoadingOverlay(false);
            const mappedResult = snapshot.docs.map( el => {
                return {
                    id: el.id,
                    ...el.data(),
                }
            });
            if (observer.next !== undefined) {
                observer.next({
                    items: mappedResult
                });
            }
        }, (error) => {
            // Getting the Error details.
            Vue.showLoadingOverlay(false);
            showMessage({
                text: error.message,
                type: "error"
            });
            if (observer.error !== undefined) {
                observer.error({
                    code : error.code,
                    message : error.message,
                    details : "",
                });
            }
        });
};


export default {
    get,
    observe
};