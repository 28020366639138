<template>
    <v-row justify="center">
        <p class="mb-10">
            {{title}}
        </p>
    </v-row>
</template>

<script>
    export default {
        name: "HeaderView",
        props:{
            title:{
                type:String,
                default:"Nome View Empty"
            }
        }
    }
</script>

<style lang="scss" scoped>
    p{
        font-size: 30px;
    }
</style>
