import apiFunctions from './apiFunctions'
import utils from '@/helpers/utils'


const saveVideoClip = (params) => apiFunctions('saveVideoClip',params);

const deleteVideoClip = (id) => apiFunctions('deleteVideoClip',{id});

const getVideoClips = (params) => {
        let p={};
        for (const [key, value] of Object.entries(params)) {
            debugger;
            let res =key.split(".");
            if (res.length>1){
                p[res[0]]={[res[1]]:value}
            }else{
                p[key]=value;
            }

        }
        params=utils.objectEmpty(p)?params:p;

    return apiFunctions('getVideoClips',params);
};

 const getVideoClipsTags = (params) => apiFunctions('getVideoClipsTags', params);









export {
    // testHelloWord,
    // errorWord,
    getVideoClipsTags,
    deleteVideoClip,
    saveVideoClip,
    getVideoClips
}
