import env from '@/env';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

firebase.initializeApp(env.FIREBASE_CONFIG);

export default firebase;

//https://firebase.google.com/docs/firestore/security/get-started#auth-required
