<template>
    <v-text-field
            v-model="model[element]"
            :label="field.label"
            :placeholder="field.label"
            :readonly="field.readonly"
    ></v-text-field>
</template>

<script>
    import elementsMixin from "./elementsMixin"
    export default {
        name: "textField",
        mixins: [elementsMixin],
        mounted() {


            if ((!this.model[this.element]) && this.field.preCompiled) {
                if (typeof this.field.preCompiled == 'function') {
                    this.$set(this.model, this.element, this.field.preCompiled());
                }
            }

        }


    }
</script>

<style lang="scss">

    .text-field-line-height{
        height: 5px;
    }

</style>
