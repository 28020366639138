<template>
    <div>
        <div v-if="!loggedIn">
            <v-icon
                slot="icon"
                color="warning"
                size="36"
            >
                mdi-lock
            </v-icon>
                Per continuare è necessario effettuare la login
        </div>
        <div v-else>
            <view-video-clip/>
        </div>


  </div>
</template>


<script>
  import ViewVideoClip from '@/views/VideoClip'
  import { mapGetters } from 'vuex';
  export default {
    name: 'home',
    components:{
        ViewVideoClip
    },
    computed: {
        ...mapGetters('user', {
        loggedIn: 'loggedIn',
        userProfile: 'userProfile'
      }),
    },

  }
</script>
