<template>
    <v-container class="my-1" fluid>
        <HeaderView :title="$t('word.videoclip')"/>
        <saveVideoClip/>
        <list-video-clip></list-video-clip>
    </v-container>
</template>

<script>
    import saveVideoClip from "@/components/videoClip/SaveVideoClip"
    import ListVideoClip from "@/components/videoClip/ListVideoClip";
    import HeaderView from "@/components/layout/HeaderView";
    export default {
        name: "Film",
        components:{
            HeaderView,
            ListVideoClip,
            saveVideoClip
        },

    }
</script>

<style scoped>

</style>
