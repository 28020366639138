import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import StoreFront from '@/views/StoreFront'
import VideoClip from '@/views/VideoClip'
import auth from "./auth"
Vue.use(VueRouter);
import store from './store';

const routes = [


  {
    path: '*',
    component: () => import(/* webpackChunkName: "about" */ './views/Error404.vue')
  },

  { path: '/', redirect: '/videoclip' },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/videoclip',
    name: 'VideoClip',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
      component: VideoClip
    //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
  },

  {
    path: '/storefront',
    name: 'StoreFront',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ './views/StoreFront.vue')
      component: StoreFront
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.BASE_URL,
});


function routerAuth(requiresAuth,loggedIn,next){
  if(requiresAuth && !loggedIn){
    next('/login');
  }else{
    next()
  }
}


router.beforeEach((to, from , next)=>{

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    let  loggedIn =  store.state.user.loggedIn;
    if (loggedIn===undefined){
      (async()=>{
        loggedIn = await auth.checkAuthStatus();
        routerAuth(requiresAuth,loggedIn,next);
      })();
    }else{
      routerAuth(requiresAuth,loggedIn,next);
    }




});





export default router;
