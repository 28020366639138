import TableBuilderVuetify from './components/TableBuilder';

export default {
    install(Vue) {
        Vue.component('table-builder-vuetify', TableBuilderVuetify);
    }
}

export {TableBuilderVuetify}

