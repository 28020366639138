var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",class:[
    _vm.node.cssClass || '',
    {
      expanded: _vm.expanded,
      draggable: _vm.dragEnabled && !_vm.options.hasDragHandle,
      dragged: _vm.dragged,
      evidenceNode: _vm.evidenceNode,
      'has-drag-handle': _vm.dragEnabled && _vm.options.hasDragHandle,
      'drag-over': _vm.dragOver,
      'no-drop': _vm.treeModel.isDragging() && !_vm.canDrop
    }
  ],style:({
    ...(_vm.expanded &&
      _vm.theme.primaryColor && { backgroundColor: _vm.theme.primaryColor }),
    ...(_vm.dragged &&
      _vm.theme.draggedItemBgColor && {
        backgroundColor: _vm.theme.draggedItemBgColor
      }),
    ...(_vm.dragged &&
      _vm.theme.draggedItemBoxShadow && {
        'box-shadow': _vm.theme.draggedItemBoxShadow
      }),
    ...(_vm.dragOver &&
      _vm.theme.primaryColor && { borderColor: _vm.theme.primaryColor }),
    ...(_vm.dragOver &&
      _vm.theme.dropZoneBgColor && { backgroundColor: _vm.theme.dropZoneBgColor }),
    ...(_vm.evidenceNode &&
      _vm.theme.secondaryColor && { backgroundColor: _vm.theme.secondaryColor }),
  }),attrs:{"role":"button","draggable":_vm.dragEnabled && !_vm.options.hasDragHandle,"aria-expanded":_vm.node.isLeaf ? undefined : _vm.expanded},on:{"mousedown":_vm.onMouseDown,"click":_vm.onClick,"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"dragstart":_vm.onDragStart,"dragover":_vm.onDragOver,"drop":_vm.onDrop,"dragend":_vm.onDragEnd,"focus":_vm.onFocus}},[(_vm.dragEnabled && _vm.options.hasDragHandle)?_c('div',{staticClass:"drag-handle",on:{"mousedown":function($event){return _vm.$el.setAttribute('draggable', 'true')},"mouseup":function($event){return _vm.$el.setAttribute('draggable', 'false')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2m0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8m0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14m6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6m0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8m0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14"}})])]):_vm._e(),(_vm.selectable)?_c('input',{attrs:{"type":"checkbox","disabled":_vm.node.selectable === false,"aria-label":_vm.node.label},domProps:{"checked":_vm.selected},on:{"click":function($event){$event.stopPropagation();},"change":_vm.onSelect}}):_vm._e(),_c(_vm.itemComponent,{tag:"component",staticClass:"inner-item",class:_vm.node.id,attrs:{"item":_vm.node,"expanded":_vm.expanded,"dragged":_vm.dragged}},[_vm._t("default")],2),(!_vm.node.isLeaf)?_c(_vm.arrowComponent,{tag:"component",attrs:{"theme":_vm.theme,"expanded":_vm.expanded,"item":_vm.node}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }