import utils from '@/helpers/utils'
import GhostTreeView from "@/library/treeView";
import {nanoid} from "nanoid";


const CONFIG_ENTITIES_CONTENT_TYPE={

    video_clips:{
        entity:"shelf",
        entityChild:"video",
    },

    groups_with_photos:{
        entity:"shelf",
        entityChild:"group",
    },
    groups:{
        entity:"shelf",
        entityChild:"group",
    },
};


const CONFIG_ENTITIES_CHILDREN={
    shelf_video:{
        entity:"video",
        entityChild:null,
    },

    shelf_group:{
        entity:"group",
        entityChild:"video",
    },

    group_video:{
        entity:"video",
        entityChild:null,
    },

};



export default class extends GhostTreeView {
    constructor(tree) {
        super(tree);
        this.storeFront=[];
        this.pathEntities="";
    }

    _addRootSection(){
        return {
            id: "root",
            "entity":"shelf",
            "entityChild":"shelf",
        }
    };

    _addItemsSection(){
        return {
            items:[]
        }
    }


    _setEntity(children){
        let result="";
        if (children.contentType){
            result=CONFIG_ENTITIES_CONTENT_TYPE[children.contentType];
            this.pathEntities=`${result.entity}_${result.entityChild}`;
        }else{
           if (children.legacyServerId){
               result=CONFIG_ENTITIES_CHILDREN["group_video"];
           }else{
            result=CONFIG_ENTITIES_CHILDREN[this.pathEntities];

           }
        }

        return result;
    }

    _mappingInput(tree,parentId){

        if (tree.content){
            utils.renameItem(tree,'content','children');
            tree.children.forEach(children=>{
                children["parent"]=parentId;
                utils.renameItem(children,'title','label');
                utils.renameItem(children,'name','label');
                let propEntity=this._setEntity(children);
                children["idChild"]=nanoid();
                children["entity"]=propEntity.entity;
                children["entityChild"]=propEntity.entityChild;
                if (children.legacyServerId) {
                    children["idItem"]=children.id;
                    children.id=children["idChild"];
                }
                this._mappingInput(children,children.id);

            });
        }


    }


    _setContentVideoClip(objChildren,videoClips){
        let content=[];
        videoClips.forEach(videoClip=>{
            content.push(videoClip.idItem)
        });

        objChildren["content"]=content;


    }


    _copyValue(objChildren,children,name){
        if (children[name]){
            objChildren[name]=children[name];
        }
    }


    _mappingOutput(tree){

        let objChildren={};
        tree.forEach(item=>{
            objChildren["title"]=item["label"];

            this._copyValue(objChildren,item,'id');
            this._copyValue(objChildren,item,'contentType');
            this._copyValue(objChildren,item,'size');
            this._copyValue(objChildren,item,'imageRef');

            if (item.contentType==="video_clips"){
                this._setContentVideoClip(objChildren,item["children"]);
                this.storeFront.items.push(objChildren);
                objChildren={};
            }else{
                objChildren["content"]=[];
                item["children"].forEach(child=>{
                    let objChild={};
                    utils.renameItem(child,'label','name');
                    this._copyValue(objChild,child,'id');
                    this._copyValue(objChild,child,'name');
                    this._copyValue(objChild,child,'imageRef');
                    this._setContentVideoClip(objChild,child["children"]);
                    objChildren["content"].push(objChild);
                });
                this.storeFront.items.push(objChildren);
                objChildren={};
            }

        });

    }


    init(){
        this.tree={...this.tree,...this._addRootSection()};
        utils.renameItem(this.tree,'items','content');
        this._mappingInput(this.tree,"root");
        this.output={...this.tree}
    };

    updateStoreFront(tree){
        this.output=tree;
    };

    getMappingOutput(){
        let tree=[...this.output.children];
        utils.renameItem(tree,'children','items');
        this.storeFront={
            items:[]
        };
        this._mappingOutput(tree);
        return this.storeFront;
    }


}
