<template>
    <div>
      <v-app-bar dark class="primary" app :clipped-left="$vuetify.breakpoint.lgAndUp" >
        <v-app-bar-nav-icon app v-if="loggedIn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase white--text">
          <span class="font-weight-light">Facebrity Backoffice</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="loggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
                    v-bind="attrs"
                    v-on="on">
              <img :src="userProfile.picture" />
            </v-avatar>
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
                v-else
                text
                @click="login"
        >
          Login
        </v-btn>
      </v-app-bar>


      <v-navigation-drawer
              app
              v-model="drawer"
              temporary
      >
        <list-items-menu/>
      </v-navigation-drawer>
   </div>


</template>

<script>
  import { mapActions} from 'vuex';
  import ListItemsMenu from "./ListItemsMenu";
  export default {
    name: '',
    components: {ListItemsMenu},
    props:['loggedIn','userProfile'],
    data(){
      return {
        drawer:false
      }
    },


    methods: {
      ...mapActions('user', {
        loginUser: 'login',
        logoutUser: 'logout'
      }),

      async login(){
        await this.loginUser();
        this.$router.push('/videoClip')
      },

      async logout(){
        await this.logoutUser();
        this.$router.push('/login')
      }


    }




  }
</script>
