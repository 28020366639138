<template>
    <div>
        <v-icon  v-for="(action, index) in actions" :key="index" v-if="checkShowAction(action.name)"
                @click="clickAction(action.event)"
                small
                class="mr-2"
        >
            {{action.ico}}
        </v-icon>

    </div>




</template>

<script>

    const ACTIONS=[

        {
            name:'edit',
            ico:'mdi-pencil',
            event:'onActionEdit'
        },
        {
            name:'delete',
            ico:'mdi-delete',
            event:'onActionDelete'
        }


    ];

    export default {
        name: "ActionTable",
        props:["item"],
        data(){

            return{
                actions:ACTIONS,
            }
        },

        methods:{
            clickAction(event){
                this.$emit('onClickAction',this.item,event);
            },

            checkShowAction(action){
                return this.item.actions && this.item.actions[action];
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>

