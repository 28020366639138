<template>
    <v-combobox
            :disabled="getReadOnlyField(field)"
            v-model="model[element]"
            :label="field.label"
            :items="field.items"
    ></v-combobox>
</template>

<script>
    import elementsMixin from "./elementsMixin"
    export default {
        name: "ComboBox",
        mixins: [elementsMixin],
        mounted(){

            if ( (this.model[this.element]!==undefined) && (!this.model[this.element].value)){
                this.model[this.element]={...this.field.items.find(item=>item.value===this.model[this.element])};
            }



        }
    }
</script>

<style scoped>

</style>
