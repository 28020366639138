<template>
    <div>
        <v-btn v-if="data===null"
               bottom
               color="accent"
               dark
               fab
               fixed
               right
               @click="openDialogInsertFilm()"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <dialogVideoClip :paramsDialog="{
                            fullscreen:false
                        }">
        </dialogVideoClip>
        <ConfirmDlg ref="confirm" />
    </div>
</template>

<script>
    import {mapGetters} from "vuex"
    import firebase from "firebase/compat/app";
    import ConfirmDlg from "@/components/dialog/ConfirmDialog";
    import DialogVideoClip from "@/components/dialog/FormDialog"
    import {saveVideoClip} from "@/functions/videoClipFunctions"
    import file from "@/helpers/file"

    export default {
        name: "SaveVideoClip",
        props:{

            data:{
                type:Object,
                default:null,
            }

        },
        components:{
            DialogVideoClip,ConfirmDlg
        },
        data: () => ({
            uploadValue:0,
            form:{},
            enabledBtnProcess:false,
            showProgressBar:false,
        }),

        computed: {
            ...mapGetters('user', {
                userProfile: 'userProfile'
            }),
        },

        methods:{

            openDialogInsertFilm(){
                let data = {};
                let params={
                    steps:[{
                        title:this.$t("text.newVideoClip"),
                        component:"videoClip/VideoClipForm",
                    }],
                    data
                };

                EventBus.emit("showFormDialog",{params,show:true});
            },

            async closeDialog(){
                if (await this.$refs.confirm.open(
                  "Attenzione",
                  "Continuando perderai i dati inseriti vuoi procedere?"
                )
                ) {
                    EventBus.emit("showFormDialog",{params:null,show:false});
                    this.$emit("onCancel");
                }
            },


        }


    }
</script>

<style scoped>

</style>
