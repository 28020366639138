import store from '../store';

const typeMessage={
    "success":{color:"success"},
    "error":{color:"danger"},

};

const showMessage = (payload)=>{
    payload.color=typeMessage[payload.type].color;
    if (!payload.hasOwnProperty('timeout')){
        payload.timeout=1000;
    }
    store.commit('snackbar/SHOW_MESSAGE',payload);
};

export {
    showMessage
}
