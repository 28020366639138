import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import {showMessage} from './helpers/messages'
import UUID from "vue-uuid";
import env from "@/env"
import VueI18n from 'vue-i18n'
import {DICTIONARY} from "./languages/en";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: DICTIONARY.language, // set locale
  messages:DICTIONARY, // set locale messages
});





Vue.use(UUID);

Vue.config.productionTip = false;
Vue.prototype.$message = showMessage;
Vue.prototype.$env = env;

import FormBuilderVuetify from "./plugins/components/builders/form-builder-vuetify/src/main"
Vue.use(FormBuilderVuetify);
import TableBuilderVuetify from "./plugins/components/builders/table-builder-vuetify/src/main"
Vue.use(TableBuilderVuetify);
import FinderCustom from "./plugins/components/vue-finder/src/index"
Vue.use(FinderCustom);
import ConfirmDialogVuetify from "./plugins/components/ui/dialog/confirm-dialog-vuetify/src/main"
Vue.use(ConfirmDialogVuetify);

import {LoadingOverlay} from './plugins/components/ui/loadingOverlay'
Vue.use(LoadingOverlay);


window.EventBus = new class
{
  constructor()
  {
    this.vue = new Vue();
  }

  emit(event, data = null)
  {
    this.vue.$emit(event, data);
  }

  listen(event, callback)
  {
    this.vue.$on(event, callback);
  }

  off(event, callback)
  {
    this.vue.$off(event, callback);
  }
};

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
