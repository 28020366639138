import FormBuilderVuetify from './components/FormBuilder';

export default {
    install(Vue) {
        Vue.component('form-builder-vuetify', FormBuilderVuetify);
    }
}

export {FormBuilderVuetify}

