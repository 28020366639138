import {showMessage} from '@/helpers/messages';
import firebase from "firebase/compat/app";
const storageFileUpload = (path,imageData,callBack) => {

    let result=false;
    if (imageData !== null){
    const storageRef=firebase.storage().ref().child(path).put(imageData);
    storageRef.on(`state_changed`,snapshot=>{
        callBack((snapshot.bytesTransferred/snapshot.totalBytes)*100,false);
      }, error=>{
          showMessage({
              text: error.message,
              type: "error"
          });
      },
      ()=>{
          callBack(100,true);
          result=true;
          storageRef.snapshot.ref.getDownloadURL().then((url)=>{
              result =true;
          });
      }
    );
    }else{
    showMessage({
        text: "Nessun immagine caricata",
        type: "error"
    });
    }

    return result;

};

const storageFileDownload = (path,callback) => {
    firebase.storage().ref().child(path).getDownloadURL()
      .then((url) => {
        callback(url)
      })
      .catch((error) => {
          // Handle any errors
          showMessage({
              text: "download immagine non riuscita",
              type: "error"
          });
      });

};


const getExtensionFile = (filename) => {
    return filename.split('.').pop();
};


const getImageDetails= (payload) => {


    return new Promise((resolve, reject) => {
        try {
            let image = new Image();;
            image.src = URL.createObjectURL(payload);

            image.onload = function() {
                resolve(this);
            };


        } catch {

            reject('error load image')
        }finally {
            URL.revokeObjectURL(payload);
        }
    });



}

export default {
    getImageDetails,
    storageFileUpload,
    storageFileDownload,
    getExtensionFile
}
