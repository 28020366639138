<template>
    <v-row justify="center">
        <v-dialog
                v-model="dialog"
                :persistent="params.persistent"
                :max-width="params.maxWidth"
                :fullscreen="params.fullscreen"
                :hide-overlay="params.hideOverlay"
                :transition="params.transition"
        >


            <v-card  v-if="(steps!=null)">
                <v-toolbar
                        v-if="params.fullscreen"
                        dark
                        color="primary"
                >
                    <v-btn
                            icon
                            dark
                            @click="onClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{step.title}}</v-toolbar-title>
                </v-toolbar>


                <v-card-title v-if="!params.fullscreen">
                    <span class="headline">{{step.title}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container >

                        <dynamic-form :key="key" @onClose="closeForm"  @onDataEvent="senderDataEvent" :type="step.component" :data="data"/>
                        <slot name="container"></slot>

                    </v-container>


                </v-card-text>


                <v-card-actions v-if="!params.fullscreen" >
                    <v-spacer></v-spacer>
                    <slot name="buttons"></slot>
                </v-card-actions>

            </v-card>

        </v-dialog>
    </v-row>
</template>


<script>
    import DynamicForm from "@/components/form/DynamicForm";

    export default {
        name: "FormDialog",

        components:{DynamicForm},

        props:{
            noCloseExitModal:{
                type:Boolean,
                default:false
            },
            paramsDialog:{
                type:Object,
                default:{}
            },
        },

        data(){
            return{
                dialog: false,
                showLoading: false,
                nStep:0,
                steps:null,
                key:null,
                options:{},
                data:{},
                params:{
                    persistent:false,
                    maxWidth:"600px",
                    fullscreen:false,
                    hideOverlay:false,
                    options:{},
                },
            }

        },

        computed: {

            step(){
                return this.steps[this.nStep]
            },

        },

        mounted() {


            this.params={...this.params,...this.paramsDialog};

            EventBus.listen("showFormDialog", ({params,show}) => {
                if (show){
                    this.key=this.$uuid.v4();
                    this.nStep=0;
                    this.steps=params.steps;
                    if (params.hasOwnProperty('options')){
                        this.params={...this.params,...params.options}
                    }
                    if (params.data!==undefined){
                        this.data=params.data;
                    }
                }

                this.dialog=show;
            });
        },

        methods:{

            nextStep(params){
                this.nStep++;
                this.params=params;
            },

            senderDataEvent(data){
                this.$emit('onDataEvent',data);
            },

            closeForm(){
                this.dialog=false;
            }

        }

    }
</script>

<style scoped>

</style>
