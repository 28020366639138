<script>
import TreeModel from "../utils/tree-model";
import FinderList from "./FinderList";
import FinderListActions from "./FinderListActions";
/**
 * Render the tree of an item and its selected children.
 *
 * @param {Object} h          `createElement` object
 * @param {Object} context    Context component
 * @param {Object} item       Item to render
 * @return Rendering object
 */
function renderTree(h, context, item) {

  if ((!item) || item.entityChild===null) {
    return null;
  }

  const expandedChild = item.children.find(child =>
    context.treeModel.isNodeExpanded(child.id)
  );

  const options = {
    sortBy: context.sortBy,
    itemComponent: context.itemComponent,
    arrowComponent: context.arrowComponent,
    dragImageComponent: context.dragImageComponent,
    dropZoneComponent: context.dropZoneComponent,
    theme: context.theme,
    hasDragHandle: context.hasDragHandle,
    canDrop: context.canDrop,
    onlyDropChildren:context.onlyDropChildren
  };

    const itemListActions = (
      <FinderListActions
        tree-model={context.treeModel}
        parent={item}
        caption={context.entitiesCaption}
        nodeSelected={context.nodeSelected}/>
);


  const itemList = (
    <FinderList
      ref="rootList"
      tree-model={context.treeModel}
      parent={item}
      items={item.children}
      selectable={context.selectable}
      drag-enabled={context.dragEnabled}
      options={options}
      has-expanded-item={!!expandedChild}
    />
  );

  return (


    <div>
    <div class="list-actions">
      {itemListActions}
      </div>
      <div class="list-container">
      {itemList}
    {expandedChild && renderTree(h, context, expandedChild)}
</div>
    </div>



    // <div>
    //
    //         <div class="list-container">
    //   <div class="list-actions">
    //   {itemListActions}
    //   </div>
    //             {itemList}
    //
    //             {expandedChild && renderTree(h, context, expandedChild)}
    //         </div>
    // </div>
  );
}

/**
 * Get a value animated by a ease out Bezier curve.
 */
function easeOutQuad(elapsedTime, start, end, duration) {
  return -end * (elapsedTime /= duration) * (elapsedTime - 2) + start;
}

export default {
  name: "Finder",
  components: {
    FinderList,FinderListActions
  },
  props: {
    /**
     * Data of the tree.
     *
     * ```js
     * const tree = {
     *   id: "test",
     *   label: "Test",
     *   children: [{
     *      id: "fruits",
     *      label: "Fruits",
     *      children: [{
     *        id: "orange",
     *        label: "Orange",
     *        selected: true
     *      }, {
     *        id: "apple",
     *        label: "Apple",
     *        selectable: false
     *      }, {
     *        id: "banana",
     *        label: "Banana"
     *      }],
     *   }]
     * };
     * ```
     */
    tree: {
      type: Object,
      required: true
    },

    entitiesCaption:{
        type: Object,
        default:undefined
    },

    externalInput:{
        type: Object,
        required: true
    },

   onlyDropChildren:{
      type: Boolean,
      default: false
    },

    /**
     * Enable the selection of items.
     *
     * ::: tip
     * You can disable the selection on some items by setting them `selectable: false`.
     * :::
     *
     * ::: tip
     * You can set some items selected by default by setting them `selected: true`.
     * :::
     */
    selectable: {
      type: Boolean,
      default: false
    },
    /**
     * Whether all its descendants should be automatically selected when an item is selected.
     */
    autoSelectDescendants: {
      type: Boolean,
      default: false
    },
    /**
     * Whether all its descendants should be automatically deselected when an item is deselected.
     */
    autoDeselectDescendants: {
      type: Boolean,
      default: false
    },
    /**
     * Enable the drag & drop of items.
     */
    dragEnabled: {
      type: Boolean,
      default: false
    },
    /**
     * Whether a drag handle is displayed to drag items.
     */
    hasDragHandle: {
      type: Boolean,
      default: false
    },
    /**
     * Function that indicates if a dragged item can be dropped on another.
     *
     * ```js
     * const canDrop = (target, source) => {
     *   return target.id !== 'a-readonly-item';
     * };
     * ```
     *
     * @param {string} target ID of the drop target
     * @param {string} source ID of the dragged item
     * @return Should return `true` if `source` can be dropped on `target`
     */
    canDrop: {
      type: Function,
      default: undefined
    },
    /**
     * Function to filter displayed items.
     *
     * ```js
     * const filter = item => /^$myitem/.test(item.label);
     * ```
     */
    filter: {
      type: Function,
      default: undefined
    },
    /**
     * Function to sort displayed items.
     *
     * ```js
     * const sortBy = (item1, item2) => item1.id > item2.id ? -1 : item1.id < item2.id ? 1 : 0;
     * ```
     */
    sortBy: {
      type: Function,
      default: undefined
    },
    /**
     * ID of the item expanded when loaded.
     */
    defaultExpanded: {
      type: String,
      default: undefined
    },
    /**
     * Custom component to render items.
     */
    itemComponent: {
      type: [String, Object],
      default: undefined
    },
    /**
     * Custom component to render arrows (on items with children).
     */
    arrowComponent: {
      type: [String, Object],
      default: undefined
    },
    /**
     * Custom component to render drag image.
     */
    dragImageComponent: {
      type: [String, Object],
      default: undefined
    },
    /**
     * Custom component to render drop zones.
     */
    dropZoneComponent: {
      type: [String, Object],
      default: undefined
    },
    /**
     * Styling options.
     *
     * ```js
     * const theme = {
     *   primaryColor: '#0b79d0',
     *   arrowColor: 'black',
     *   separatorColor: '#ccc',
     *   separatorWidth: '1px',
     *   dropZoneBgColor: 'rgba(33, 150, 243, 0.2)',
     *   draggedItemBgColor: 'rgba(33, 150, 243, 0.5)',
     * };
     * ```
     */
    theme: {
      type: Object,
      default: () => ({})
    },
    /**
     * Duration of the scroll animation (in milliseconds).
     * When an item is expanded, the finder is scrolled to the right,
     * using an animation. This parameter defines the duration of this animation.
     *
     * Set `0` for no animation.
     */
    scrollAnimationDuration: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      treeModel: {},
      nodeSelected:{}

    };
  },
  watch: {
      tree(newTree, oldTree) {
          this.treeModel.root = newTree;
      },
      filter(newFilter) {
          this.treeModel.filter = newFilter;
      },
      autoSelectDescendants(autoSelectDescendants) {
          this.treeModel.autoSelectDescendants = autoSelectDescendants;
      },
      autoDeselectDescendants(autoDeselectDescendants) {
          this.treeModel.autoDeselectDescendants = autoDeselectDescendants;
      },
      externalInput: {
          // This will let Vue know to look inside the array
          deep: true,

          // We have to move our method to a handler field
          handler(dataExternal, dataOld) {
              if (dataExternal !== dataOld) {
                  this.executeAction(dataExternal);
              }
          }
      },
  },
  beforeCreate() {
    Object.defineProperty(this.$options.propsData, "tree", {
      configurable: false
    });
  },
  created() {
    this.cretatedTreeModel(this.tree);


      EventBus.listen("refreshTreeModel", (tree) => {
            this.cretatedTreeModel(tree);
      });



  },
  methods: {

      cretatedTreeModel(tree){

          console.log(tree);

          this.treeModel = new TreeModel(tree, {
              filter: this.filter,
              defaultExpanded: this.defaultExpanded,
              autoSelectDescendants: this.autoSelectDescendants,
              autoDeselectDescendants: this.autoDeselectDescendants
          });
          this.addListners();
      },

      executeAction(data){

          let action=data.hasOwnProperty('inputData')?data.inputData.action:data.action;
          if (data.outputData && data.outputData.parent){
              data.outputData["parent"]=data.outputData.parent;
          }


          switch (action) {
              case 'add':
                  this.treeModel.addNodeToParent(data.inputData.parent.id,data.outputData,0);
                  break;
              case 'update':
                  this.treeModel.updateNodeToParent(data.outputData);
                  break;

              case 'delete':
                  this.treeModel.removeNodeToParent(data.nodeSelected);
                  break;
          }
      },

      addListners(){
          this.treeModel.on("expand", (expanded, sourceEvent) => {
              if (sourceEvent !== "dragover") {
                  this.$nextTick(() => {
                      this._scrollToRight(this.scrollAnimationDuration);
                  });
              }

              /**
               * This event is triggered when an item has been expanded.
               *
               * ```html
               * <Finder :tree="tree" @expand="onExpand"/>
               * ```
               *
               * ```js
               * onExpand({ expanded, sourceEvent }) {
               *   console.log(
               *     `Items with ${expanded.join()} IDs are now expanded`
               *   );
               * }
               * ```
               *
               * @event expand
               * @type {object}
               * @property {Array<string>} expanded    IDs of expanded items
               * @property {string}        sourceEvent Name of the event that triggered the action
               *                                       (`"click"`, `"focus"`, `"drop"`, `"dragover"` or `undefined`)
               */
              this.$emit("expand", {
                  expanded,
                  sourceEvent
              });
          });
          this.treeModel.on("click", (node,index) => {
              this.nodeSelected=node;
              this.$emit("clickNode", {
                  node,index
              });
          });


          this.treeModel.on("updateChildren", ({action,index,tree,parentId,nodeId}) => {
              this.$emit("updateChildren", {action,index,tree,parentId,nodeId});
          });



          this.treeModel.on("actionList", ({action,parent,nodeSelected}) => {
              this.$emit("actionList", {
              action,parent,nodeSelected
              });
          });


          this.treeModel.on("select", selected => {
              /**
               * This event is triggered when an item has been selectfed.
               *
               * ```html
               * <Finder :tree="tree" @select="onSelect"/>
               * ```
               *
               * ```js
               * onSelect({ selected }) {
               *   console.log(
               *     `Items with ${selected.join()} IDs are now selected`
               *   );
               * }
               * ```
               *
               * @event select
               * @type {object}
               * @property {Array<string>} selected IDs of selected items
               */
              this.$emit("select", {
                  selected
              });
          });
          this.treeModel.on("move", ({moved, to, index }) => {
              /**
               * This event is triggered when an item has been moved by drag and drop.
               * When an item is dropped on a dropzone between two elements, a `index` is also provided.
               *
               * ```html
               * <Finder :tree="tree" @move="onMove"/>
               * ```
               *
               * ```js
               * onMove({ moved, to, index }) {
               *   console.log(
               *     `Item with ${moved} ID has been moved
               *     to its new parent with ${to} ID`
               *   );
               * }
               * ```
               *
               * @event move
               * @type {object}
               * @property {string} moved ID of the moved item
               * @property {string} to    ID of the parent on which the item has been moved to
               * @property {number} index Index of the dropzone
               */

              this.$emit("move", {
                  moved,
                  to,
                  index
              });
          });
      },


    /**
     * Set a given item expanded.
     *
     * ```html
     * <Finder :tree="tree" ref="myFinder" />
     * ```
     *
     * ```js
     * this.$refs.myFinder.expand('item111');
     * ```
     *
     * @param {string} itemId      ID of the item to expand
     * @param {string} sourceEvent Source event that will appear in `expand` event
     *                             (`api` by default)
     * @public
     * @since 1.6.0
     */
    expand(itemId, sourceEvent = "api") {
      this.treeModel.expandNode(itemId, sourceEvent);
    },
    _scrollToRight(scrollDuration) {
      const { scrollLeft, scrollWidth, offsetWidth } = this.$el;

      if (scrollDuration === 0) {
        this.$el.scrollLeft = scrollWidth;
        return;
      }

      const scrollDistance = scrollWidth - offsetWidth - scrollLeft;
      if (scrollDistance <= 0) {
        return;
      }

      let oldTimestamp = performance.now();
      let duration = 0;
      const step = newTimestamp => {
        const stepDuration = newTimestamp - oldTimestamp;
        duration += stepDuration;

        if (duration >= scrollDuration) {
          this.$el.scrollLeft = this.$el.scrollWidth;
          return;
        }

        oldTimestamp = newTimestamp;

        this.$el.scrollLeft = easeOutQuad(
          duration,
          scrollLeft,
          scrollDistance,
          scrollDuration
        );
        window.requestAnimationFrame(step);
      };
      window.requestAnimationFrame(step);
    }
  },
  render(h) {
    return (
      <div class="tree-container">
        {this.treeModel && renderTree(h, this, this.treeModel.visibleTree)}
      </div>
    );
  }
};
</script>

<style lang="scss" scoped>
    .tree-container {
        overflow-x: auto;
        height: 100%;
        margin-top: 80px;

        .list-container {
            display: flex;
            height: 100%;
        }

        .list-actions {
            position: fixed;
            top: 154px;


        }

    }

/*.tree-container {*/
/*  overflow-x: auto;*/
/*  height: 100%;*/
/*  margin-top: 80px;*/

/*  .list-container {*/
/*    display: flex;*/
/*    height: 100%;*/
/*      .list-actions {*/
/*        display: block;*/
/*        position: inherit;*/

/*  }*/
/*  } */

/*}*/



</style>
