const isArray = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

const hasProperty = (obj, prop) => {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

const objectEmpty = (obj) => {
    Object.keys(obj).length === 0
}


const renameItem =(obj,oldName,newName) =>{
    if (obj[oldName]){
        delete Object.assign(obj, {[newName]: obj[oldName]})[oldName];
    }
}

export default{
    renameItem,
    objectEmpty,
    isArray,
    hasProperty,
};
