<template>
    <div>
        <component @onDataEvent="setDataComponent"
                   @onClose="closeForm"
                   :is="component"
                   :data="data"
                   :options="options"
                   v-if="component"
        />
    </div>

</template>
<script>

    export default {
        name: 'DynamicModule',


        props: {
            data: {
                type: Object,
                default: () => ({})

            },

            type: {
                type: String,
                default: () => ("")

            },

            module: {
                type: Object,
                default: () => ({})

            },

            options: {
                type: Object,
                default: () => ({})

            },
        },

        data() {
            return {
                component: null,
            }
        },
        computed: {
            loader() {

                if (!this.type) {
                    return null
                }
                return () => import(`./${this.type}`)
            },
        },
        mounted() {
            this.loader()
              .then(() => {
                  this.component = () => this.loader();

              })
              .catch(() => {
                  alert(`Form ${this.type} not found`)
              })
        },

        methods: {
            setDataComponent(data) {
                this.$emit("onDataEvent", data)
            },
            closeForm(){
                this.$emit('onClose');
            }
        }

    }
</script>
