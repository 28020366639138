export default {
    namespaced: true,
    state: {
        message:{
            text: "",
            color: "",
            timeout: "",
            show:false,
        }

    },

    getters:{
        getMessage: ({message}) => message,
    },

    mutations: {
        SHOW_MESSAGE(state, payload) {
            state.message.show = true;
            state.message.text = payload.text;
            state.message.color=payload.color;
            state.message.timeout = payload.timeout;
        },
        HIDE_MESSAGE(state){
            state.message.show = false;
            state.message.text = "";
        }
    },
    actions: {
        show({ commit }, payload) {
            commit("SHOW_MESSAGE", payload);
        },
    },
};