import firebase from "firebase/compat/app";
import {showMessage} from '@/helpers/messages'
import env from "@/env"
import Vue from 'vue'

const endPoint=`${env.NAME_SPACE}-`;

function getResponseClient(response){
    let data=null;
    if (response.data.hasOwnProperty('data')){
        data=response.data.data;
    }else{
        data=response.data;
    }
    return data;
}


const apiFunctions = (funcName,params={}) => {

    return new Promise((resolve, reject) => {
        Vue.showLoadingOverlay(true);
        let callApi = firebase.functions().httpsCallable(endPoint+funcName);
        callApi(params)
          .then((result) => {
              // Read result of the Cloud Function.
              Vue.showLoadingOverlay(false);
              resolve(getResponseClient(result));

          })
          .catch((error) => {
              // Getting the Error details.
              Vue.showLoadingOverlay(false);
              showMessage({
                  text: error.message,
                  type: "error"
              });
              reject({
                    code : error.code,
                    message : error.message,
                    details : error.details,
              })

          });
    });


};

export default apiFunctions;
