<template>

    <v-container v-if="parent.children || parent.children.length !== 0">
        <p  v-if="caption" class="subtitle-1">{{caption[parent.entityChild]}}</p>



                <v-row >
                    <v-col>
                    <v-btn
                            color="#e91e63"
                            @click="onAction('add')"
                            class="ma-2 white--text"
                            small
                            fab
                    >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                    </v-col>
                    <v-col>
                    <v-btn

                            v-if="parent.entityChild!=='video'"
                            :disabled=disabledActions
                            @click="onAction('update')"
                            color="#e91e63"
                            class="ma-2 white--text"
                            small
                            fab
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>

                    </v-col>
                    <v-col>
                    <v-btn
                            :disabled=disabledActions
                            color="#e91e63"
                            class="ma-2 white--text"
                            small
                            @click="onAction('delete')"
                            fab
                    >
                        <v-icon dark>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                        </v-col>
                </v-row>













<!--        <p style="position: absolute;top: 139px;" v-if="caption" class="subtitle-1">{{caption[this.parent.entityChild]}}</p>-->
<!--        <v-row >-->
<!--            <v-col>-->
<!--            <v-btn-->
<!--                    color="#e91e63"-->
<!--                    @click="onAction('add')"-->
<!--                    class="ma-2 white&#45;&#45;text"-->
<!--                    small-->
<!--                    fab-->
<!--            >-->
<!--                <v-icon dark>-->
<!--                    mdi-plus-->
<!--                </v-icon>-->
<!--            </v-btn>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--                <v-row >-->
<!--                    <v-col>-->
<!--            <v-btn-->

<!--                    :disabled=disabledActions-->
<!--                    @click="onAction('update')"-->
<!--                    color="#e91e63"-->
<!--                    class="ma-2 white&#45;&#45;text"-->
<!--                    small-->
<!--                    fab-->
<!--            >-->
<!--                <v-icon dark>-->
<!--                    mdi-pencil-->
<!--                </v-icon>-->
<!--            </v-btn>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                        <v-row >-->
<!--                            <v-col>-->
<!--            <v-btn-->
<!--                    :disabled=disabledActions-->
<!--                    color="#e91e63"-->
<!--                    class="ma-2 white&#45;&#45;text"-->
<!--                    small-->
<!--                    @click="onAction('delete')"-->
<!--                    fab-->
<!--            >-->
<!--                <v-icon dark>-->
<!--                    mdi-delete-->
<!--                </v-icon>-->
<!--            </v-btn>-->
<!--                </v-col>-->

<!--        </v-row>-->
    </v-container>
</template>

<script>
    export default {
        name: "FinderListActions",
        props: {
            nodeSelected: {
                type: Object,
                default: () => ({})
            },
            parent: {
                type: Object,
                default: () => ({})
            },
            treeModel: {
                type: Object,
                required: true
            },
            caption:{
                type:Object,
                default:undefined
            }
        },
        data(){
            return{
              disabled:true
            }
        },

        computed:{
            disabledActions(){
                return (this.parent.entityChild!==this.nodeSelected.entity);

                // if (!this.nodeSelected.entityChild || this.parent.entityChild!==this.nodeSelected.entity){
                //     return false;
                // }else{
                //     return this.parent.entityChild!==this.nodeSelected.entity;
                // }

                //return (this.nodeSelected.entityChild!==null)?(this.parent.entityChild!==this.nodeSelected.entity):false;

            }
        },

        methods:{
            onAction(action){

                let nodeSelected=this.nodeSelected.hasOwnProperty('id')?this.nodeSelected:null;
                this.treeModel.actionList({action,parent:this.parent,nodeSelected});
            }
        }
    }
</script>

<style lang="scss">

</style>
