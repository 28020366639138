import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

const config = {
    fullPage: false,
    container: null,
    loader: "spinner",
    color: "#424242",
    canCancel: false
};

export const LoadingOverlay = {
    install: Vue => {
        Vue.use(Loading);

        Vue.showloading = null;
        Vue.showLoadingOverlay = (show, params) => {
            let paramsConfig = { ...config, ...params };

            if (show && Vue.showloading == null) {
                Vue.showloading = Vue.$loading.show({
                    height: 50,
                    weight: 60,
                    container: paramsConfig.fullPage
                        ? null
                        : paramsConfig.container,
                    loader: paramsConfig.loader,
                    color: paramsConfig.color
                });
            }

            if (!show && Vue.showloading != null) {
                Vue.showloading.hide();
                Vue.showloading = null;
            }
        };
    }
};
