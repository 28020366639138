export default {
    MODE:"prod",
    NAME_SPACE:'backoffice',
    FIREBASE_CONFIG:{
        "apiKey": "AIzaSyACCPudG0Y4yqIXAmbMbHjTNZc6wc9vslU",
        "authDomain": "facebrity.firebaseapp.com",
        "databaseURL": "",
        "messagingSenderId": "398477672212",
        "projectId": "facebrity",
        "storageBucket": "facebrity.appspot.com"
    }
}
