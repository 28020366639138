<template>
    <div>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="title">
                    Menu
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{userProfile.email}}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
                dense
                nav
        >
            <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    router :to="item.route"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    import {mapGetters} from "vuex"
    export default {
        name: "ListItemsMenu",
        data(){
            return {
                items: [
                    { title: 'word.videoclip', icon: 'mdi-video-vintage' ,route:'/videoclip' },
                    { title: 'word.storefront', icon: 'mdi-bookshelf' ,route:'/storefront'},

                ],
                right: null,
            }
        },

        computed: {
            ...mapGetters('user', {
                userProfile: 'userProfile'
            })
        },

    }
</script>

<style scoped>

</style>
