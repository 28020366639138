<template>
    <v-container>
        <v-row>
            <v-col v-for="(field, key) in configForm" :key="key" :cols="setColumnLenght(field)">
            <component v-if="!hiddenField(field)"
                    :is="field.type"
                    :name="field.name"
                    :params="field.params"
                    :field="field"
                    :model="formValues"
                    :element="field.name"
                    :validate="$v"
                    :editMode="edit"
                    :optionsComponent="setOptions(field)"
                    @onComponentEvent="setComponentEvent"
            >
            </component>

            <template v-for="(errorClass, error) in errorConfig">
                <p v-if="hasOwnProperty($v.formValues[field.name],error)" :class="errorClass" :key="error">
                    {{ getErrorMessage(field, error) }}
                </p>
            </template>
            </v-col>
        </v-row>
        <v-row>
            <v-btn color="blue darken-1" text @click="onClose">{{$t(buttons.captionNo)}}</v-btn>
            <v-btn color="blue darken-1" text variant="success" type="submit" @click="$emit('onSubmitForm', formValues)" :disabled="$v.$invalid">{{$t(buttons.captionYes)}}</v-btn>
        </v-row>
    </v-container>

</template>

<script>
    import {validationMixin} from 'vuelidate'
    import TextField from "./elements/TextField"
    import ComboBox from "./elements/ComboBox"
    import FileUpload from "./elements/FileUpload"
    import ComboChips from "./elements/ComboChips"

    const ERROR_CONFIG = {
        'required': 'help is-danger',
    };

    export default {
        name: "FormBuilder",
        mixins: [validationMixin],
        props:{
            title:{
                type: String,
                default:"titolo non impostato"
            },
            initForm:{
                type: Object,
                default: null
            },
            configForm:{
                type: Array,
                default() {
                    return []
                }
            },
            buttons:{
                type: Object,
                default () {
                    return {
                        captionNo: "No",
                        captionYes: "Yes"
                    }
                }
            },
            validation: {
                type: Object,
                default: null
            },
            resetForm:{
                type:Boolean,
                default:false
            },
        },
        components: {
            TextField,FileUpload,ComboChips,ComboBox
        },
        data(){
            return {
                formValues:{},
                files: {},
                disabled:false,
                errorConfig: ERROR_CONFIG,
                edit:false,
            }
        },

        // mounted(){
        //     EventBus.listen('resetFormBuilder',() => {
        //         this.onReset();
        //     });
        // },

        // watch: {
        //     resetForm(val) {
        //
        //         if (val){
        //             this.onReset();
        //         }
        //     },
        //     deep: true
        // },


        validations() {
            return {
                formValues: this.rules
            };
        },
        created() {
            this.configForm.map(el=>{
                if (this.initForm !==null && this.initForm.hasOwnProperty(el.name)){
                    this.edit=true;
                    this.$set(this.formValues,el.name,this.initForm[el.name])
                }
            })
        },
        computed: {
            rules() {
                return this.$props.validation;
            },

            hiddenField:()=>{


                return function (field) {

                    let result=false;

                    if (field.hidden){
                        result=field.hidden
                    }

                    if (!result){
                        if (field.show){
                            if (this.formValues[field.show[0].element]){
                                result=!(field.show[0].values.includes(this.formValues[field.show[0].element].value));
                            }else{
                                result=true;
                            }
                        }
                    }


                    return (result)


                };


            },

        },
        methods:{
            // onReset(){
            //
            //     this.configForm.forEach(field=>{
            //         if (field.type==="inputHtml"){
            //             this.$set(this.formValues,field.name,"");
            //         } else if (field.type==="dateHtml") {
            //             this.$set(this.formValues,field.name, null);
            //         } else if (field.type==="fileHtml") {
            //             this.$set(this.formValues,field.name, null);
            //         }
            //     })
            // },

            setOptions(field){
                return field.options?field.options:{};
            },

            setComponentEvent({action,data}){
                this.$emit('onComponentEvent',{action,data})

            },



            setColumnLenght(field){
                if (field.hasOwnProperty('col')){
                    return field.col
                }else{
                    return 12;
                }
            },

            onClose(){
              this.$emit('onClose')
            },

            getErrorMessage(field, error) {
                let message = field.error[error];
                if (typeof message === "function") {
                    message = message.call(null, this.$v.formValues[field.name].$params[error]);
                }
                return message;
            }
        }


    }
</script>

<style lang="scss" scoped>


    .subTitle {
        color: #6c757d;
    }

    /***Errors***/
    .help{
        font-size: 15px;
        &.error {
            color: red;
        }
        &.required{
            color: #6c757d;
        }
    }

</style>
