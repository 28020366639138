export default {

    props:{
        model: {
            type: Object,
            default: null
        },
        element:{
            type: String,
            default: ""
        },
        field:{
            type: Object,
            default: {}
        },
        validate:{
            type: Object,
            default: {}
        },
        optionsComponent:{
            type: Object,
            default: {}
        },
        editMode:{
            type:Boolean,
            default:false
        }
    },

    methods:{
        getReadOnlyField(field){
            return (this.editMode && !field.editable)
        }
    }
};
