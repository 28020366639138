<template>
    <v-row class="buttonsTop">
        <v-col>
            <v-btn  class="mr-2" :disabled="!storeFrontModel.dirty" @click="saveStoreFront" depressed color="success">{{$t('buttons.saveStoreFront')}}</v-btn>
            <v-btn  class="mr-2" :disabled="storeFrontModel.dirty || !publishEnabled"  @click="publishStoreFront" depressed color="warning">{{$t('buttons.publishStoreFront')}}</v-btn>
        </v-col>
        <confirm-dialog-vuetify ref="confirm"/>
    </v-row>
</template>

<script>
    import {saveStorefront, publishStorefront} from "@/functions/storefrontFunctions"
    export default {
        name: "storeFrontActions",
        props:{
            storeFrontModel:{
                type:Object,
                default:{}
            }
        },

        data(){
          return {
              publishEnabled:true
          }
        },

        // computed:{
        //     enabledPublishStoreFront(){
        //         return ;
        //     },
        // },
        methods:{
            async saveStoreFront(){

                if (await this.$refs.confirm.open(
                  this.$t(`confirm.save.storefront.title`),
                  this.$t(`confirm.save.storefront.message`)
                )) {

                    let storeFront=this.storeFrontModel.getMappingOutput();
                    try {
                        this.storeFrontModel.dirty=false;
                        await saveStorefront(storeFront);
                    } catch {
                        this.storeFrontModel.dirty=true;
                    }
                    this.publishEnabled=true;
                    this.$message({
                        type: 'success',
                        text: this.$t('message.save.storefront.success')
                    })

                }
            },


            async publishStoreFront(){

                if (await this.$refs.confirm.open(
                  this.$t(`confirm.publish.storefront.title`),
                  this.$t(`confirm.publish.storefront.message`)
                )) {

                    await publishStorefront();
                    this.$message({
                        type: 'success',
                        text: this.$t('message.publish.storefront.success')
                    })

                }



            }
        }

    }
</script>

<style scoped>

</style>
