export default class {
    constructor(tree) {
       this.tree=tree;
       this.dirty=false;
       this.output=null;
       this.ghost=[];
    }

    _findNode(tree,id, children){
        let result;
        tree.some(item => {
            if (item.id === id) {
                result = item;
                return true
            }
            if ( item.hasOwnProperty(children) && item[children].length) {
                let subResult = this._findNode(item.children, id, children);
                if (subResult) {
                    result = subResult
                }
            }
        })
        return result
    };


    moveNode (nodeId, destination,childName){

        this._initGhost();
        const nodeToMove = this._findNode(this.ghost,nodeId,childName);
        const destinationNode = this._findNode(this.ghost,destination.id,childName);
        destinationNode.children = destinationNode.children.filter((node) => (node.id !== nodeToMove.id));
        destinationNode.children.splice(destination.pos,0,nodeToMove);
        this._refreshOutput();

    }

    updateChildren(action,index,tree,parentId,nodeId){
        let treeGhost=[{...tree}];
        let parentNode=this._findNode(treeGhost,parentId,'children');
        if (!nodeId){
            if (parentNode.children){
                this._updateChildren(parentId,parentNode.children);
            }
        }else{
            let item=parentNode.children.find(node=>node.id===nodeId);
            this._execActionNode(action,index,parentId,nodeId,item);
        }
    }

    _addNode(parentNode,item){
        if (!parentNode.children){
            parentNode.children=[];
        }

        parentNode.children.unshift(item);
    };


    _updateNode(parentNode,item,index){
        parentNode.children[index]=item
    }

    _deleteNode(parentNode,index){
        parentNode.children.splice(index,1);
    }


    _refreshOutput(){
        this.output=this.ghost[0];
        this.dirty=true;
    }
    _initGhost(){
        this.ghost=[{...this.output}];
    }

    _updateChildren(parentId,item){
        this._initGhost();
        let parentNode=this._findNode(this.ghost,parentId,'children');
        parentNode.children=item;
        this._refreshOutput();
    }

    _execActionNode(action,index,parentId,nodeId,item){
        this._initGhost();
        let parentNode=this._findNode(this.ghost,parentId,'children');

        switch (action) {
            case "add":
                this._addNode(parentNode,item);
                break;
            case "update":
                this._updateNode(parentNode,item,index);
                break;
            case "delete":
                this._deleteNode(parentNode,index);
                break;
        }

        this._refreshOutput();
    }





}
