<template>
    <v-container fluid>
        <v-combobox
                v-model="modelChips"
                :filter="filter"
                :hide-no-data="!search"
                :items="items"
                :search-input.sync="search"
                hide-selected
                :label="field.label"
                multiple
                small-chips
        >
            <template v-slot:no-data v-if="enabledActions('create')">
                <v-list-item>
                    <span class="subheading">{{$t('components.combochips.create')}}</span>
                    <v-chip
                            :color="`${options.colors[nonce - 1]}`"
                            label
                            small
                            dark
                    >
                        {{ search }}
                    </v-chip>
                </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color}`"
                        :input-value="selected"
                        label
                        dark
                        small
                >
          <span class="pr-2">
            {{ item.text }}
          </span>
                    <v-icon
                            small
                            @click="parent.selectItem(item)"
                    >
                        mdi-close
                    </v-icon>
                </v-chip>
            </template>
            <template v-slot:item="{ index, item }">
                <v-text-field
                        v-if="editing === item"
                        v-model="editing.text"
                        autofocus
                        flat
                        background-color="transparent"
                        hide-details
                        solo
                        @keyup.enter="edit(index, item)"
                ></v-text-field>
                <v-chip
                        v-else
                        :color="`${item.color}`"
                        label
                        dark
                        small
                >
                    {{ item.text }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-list-item-action @click.stop>
                    <v-btn
                            v-if="enabledActions('update')"
                            icon
                            @click.stop.prevent="edit(index, item)"
                    >
                        <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                    </v-btn>
                </v-list-item-action>
            </template>
        </v-combobox>
    </v-container>
</template>
<script>
    import elementsMixin from "./elementsMixin"
    import utils from "@/helpers/utils"
    export default {
        mixins: [elementsMixin],
        data: () => ({
            activator: null,
            attach: null,
            editing: null,
            editingIndex: -1,
            items: [
                { header: 'Select an option or create one' },
            ],
            nonce: 1,
            menu: false,
            modelChips: [],
            x: 0,
            search: null,
            y: 0,
        }),

        watch: {
            modelChips (val, prev) {
                if (val.length === prev.length) return;
                this.createChips(val);
                this.selectedItems();
            },
        },

        async created(){
            this.setHeaderText();
            await this.initItems();
            this.initChips();
        },


        computed:{
            options(){
                return {...{colors:["blue"],...this.optionsComponent}

                }
            }
        },


        methods: {

            setHeaderText(){

                this.items[0].header=this.$t(this.field.headerText);

            },

            enabledActions(action){
                return this.field.actions && this.field.actions[action];
            },


            edit (index, item) {
                if (!this.editing) {
                    this.editing = item
                    this.editingIndex = index
                } else {
                    this.editing = null
                    this.editingIndex = -1
                }
            },
            filter (item, queryText, itemText) {
                if (item.header) return false

                const hasValue = val => val != null ? val : ''

                const text = hasValue(itemText)
                const query = hasValue(queryText)

                return text.toString()
                  .toLowerCase()
                  .indexOf(query.toString().toLowerCase()) > -1
            },

            createChips(val){
                this.modelChips = val.map(v => {
                    if (typeof v === 'string') {
                        v=v.trim();
                        let insert=true;
                        if (!this.enabledActions('create')){
                            insert=this.items.find(item=>item.text===v)
                        }

                        if (v!=="" && insert){
                            v = {
                                text: v,
                                color: this.options.colors[this.nonce - 1],
                            };

                            this.items.push(v);

                            if (this.options.colors.length!==1){
                                this.nonce++
                            }else{
                                this.nonce=1;
                            }
                        }else{
                           v="delete";
                        }

                    }

                    return v
                });

                this.cleanItemDelete();

            },

            async initItems(){
                if (this.field.hasOwnProperty('execFunc')){
                    let tags=await this.field.execFunc();
                    tags.items.map(tag=>{
                        this.items.push({text:tag,color:'blue'})
                    });
                };
            },

            cleanItemDelete(){
                const index = this.modelChips.indexOf("delete");
                if (index!==-1){
                    this.modelChips.splice(index, 1);
                }
            },


            initChips(){
                if (this.model[this.element] !==undefined){
                    this.model[this.element].map(tag=>{
                        this.modelChips.push({text:tag,color:'blue'})
                    });
                }
            },

            selectedItems(){
                let items=this.modelChips.map(item=>{
                    if (!utils.hasProperty(item,'text')){
                        return item;
                    }
                    item.text=item.text.trim();
                    return item.text;
                });

                console.log(items);

                this.model[this.element]=items;

            }

        },
    }
</script>
