import apiFunctions from './apiFunctions'


const saveStorefront = (storefront) => apiFunctions('saveStorefront',storefront);
const publishStorefront = () => apiFunctions('publishStorefront');


export {
    publishStorefront,
    saveStorefront
}
