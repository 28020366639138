<template>
    <div>
    <v-snackbar top v-if="message.show" v-model="message.show" :color="message.color">
        {{ message.text }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="hideSnackbar" >Close</v-btn>
        </template>
    </v-snackbar>
    </div>
</template>

<script>
    import { mapMutations,mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters('snackbar',{
                message:'getMessage'
            }),

        },

        methods:{
            ...mapMutations('snackbar',['HIDE_MESSAGE']),
            hideSnackbar(){
                this.HIDE_MESSAGE();
            }
        }

    }
</script>
