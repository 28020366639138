<template>
       <div style="width: 100%">
            <v-card
                    class="mx-auto"
                    dense outlined
            >
                <form-builder-vuetify
                        :key="resetForm"
                        @onSubmitForm="searchVideoClip"
                        @onClose="resetFilters"
                        :configForm="formConfig.form"
                        :validation="formConfig.validate"
                        :buttons="buttonsForm">
                </form-builder-vuetify>
            </v-card>
            <table-builder-vuetify :showCustomFooterPagination="true" :showMultipleSelect="showMultipleSelect" @onDataEvent="clickAction" :data="{headers,items:videoClips.items}">
                <template v-slot:footer>
                    <v-btn :disabled="(!videoClips.cursor.previousPage)" @click="actionPage('previousPage')" text normal >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn :disabled="(!videoClips.cursor.nextPage)" @click="actionPage('nextPage')" text normal >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </template>
            </table-builder-vuetify>
           <confirm-dialog-vuetify ref="confirm"/>
       </div>

</template>

<script>
    import {getVideoClips,deleteVideoClip} from "@/functions/videoClipFunctions"
    import formBuilderMixin from '@/components/form/formBuilderMixin'
    import filters from './filters'
    export default {
        name: "listVideoClip",
        mixins: [formBuilderMixin(filters)],
        props:{
            showMultipleSelect:{
                type:Boolean,
                default: false,
            },
            removeItems:{
                type:Array,
                default:undefined
            },
            showActions:{
                type:Boolean,
                default:true,
            },
        },
        data: () => ({
            resetForm:false,
            dialog: false,
            dialogDelete: false,
            showForm:false,
            buttonsForm:{
                captionNo: "buttons.actions.reset",
                captionYes: "buttons.actions.find"
            },
            paramsSearch:{},
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'legacy Server Id', value: 'legacyServerId',sortable: false },
                { text: 'name', value: 'name' },
                { text: 'tags', value: 'tags' },
                { text: 'actions', value: 'actions', sortable: false },
            ],
            videoClips:{
                cursor:{
                    nextPage:undefined,
                    previousPage:undefined,
                },
                items:[
                    // {
                    //     id:"fwfwfwefgew34t-rgfelkjfre7",
                    //     legacyServerId:"fdfde89434jhfrew",
                    //     name:"le colline hanno gli occhi",
                    //     tags:['occhi'],
                    //     verticalImageRef:"tmp/c05g6SULotgg5n6Avyt33TN3xQc2/f998daf8-e1ba-41c7-8077-82a8945cbdea.jpeg"
                    // },
                    // {
                    //     id:"rfdegklmjnreflkjrefg-frgelkjfreljkrfeg",
                    //     legacyServerId:"ewrgfrgreg",
                    //     name:"Pirati dei caraibi",
                    //     tags:['pirati','caraibi'],
                    //     verticalImageRef:"tmp/c05g6SULotgg5n6Avyt33TN3xQc2/c621f7cf-8290-423e-946a-2d7ba19f8135.JPEG"
                    // },
                    // {
                    //     id:"vgfdlkmfdsg-refglkmjnrefd",
                    //     legacyServerId:"fdgfdgfgdgdfd",
                    //     name:"Io speriamo che me la cavo",
                    //     tags:['occhi'],
                    //     verticalImageRef:"tmp/c05g6SULotgg5n6Avyt33TN3xQc2/f998daf8-e1ba-41c7-8077-82a8945cbdea.jpeg"
                    // },
                    // {
                    //     id:"fvdslkmjfedkljmfdew-lkdweqlkewf98ui432r",
                    //     legacyServerId:"ewrgffdgggfddgfrgreg",
                    //     name:"Batman",
                    //     tags:['pirati','caraibi'],
                    //     verticalImageRef:"tmp/c05g6SULotgg5n6Avyt33TN3xQc2/c621f7cf-8290-423e-946a-2d7ba19f8135.JPEG"
                    // },

                ],
            }
        }),


        mounted () {

            this.loadListVideoClip();
            EventBus.listen("refreshListVideoClips", () => {
                this.loadListVideoClip()
            });



        },




        methods: {
            async loadListVideoClip() {
                this.videoClips=await getVideoClips(this.paramsSearch);
                this.addActions();
                this.removeItemsSelected();
            },

            addActions(){
                if (this.showActions){
                    this.videoClips.items.map(item=>{
                        return item.actions={edit:true,delete:true};
                    });
                }



            },

            actionPage(action){
                this.paramsSearch={...this.filters,...{cursor:this.videoClips.cursor[action]}};
                this.loadListVideoClip();

            },

            deleteItem (item) {

            },

            searchVideoClip(data){
                this.filters=data;
                this.paramsSearch={...data};
                this.loadListVideoClip()
            },
            resetFilters(){
                this.resetForm=!this.resetForm;
                this.searchVideoClip({});
            },

            openDialogInsertFilm(item){
                let params={
                    steps:[{
                        title:item.name,
                        component:"videoClip/VideoClipForm",
                    }],
                    data:{init:item}
                };

                EventBus.emit("showFormDialog",{params,show:true});
            },


            async removeVideoClip(item){
                if (await this.$refs.confirm.open(
                  this.$t(`confirm.delete.item.title`),
                  this.$t(`confirm.delete.item.message`,{ name: item.name })
                )
                ) {
                    await deleteVideoClip(item.id);
                    this.loadListVideoClip()
                }


            },


            clickAction({item,event}){
                switch (event) {
                    case 'onActionEdit':
                        this.openDialogInsertFilm(item);
                        break;
                    case 'onSelectedRows':
                        this.$emit("onDataEvent",item);
                        break;
                    case 'onActionDelete':
                        this.removeVideoClip(item)
                }


            },

            removeItemsSelected(){
                if (this.removeItems){
                    this.videoClips.items=this.videoClips.items.filter((item)=>{
                        return (this.removeItems.find((filter)=>item.id===filter.idItem)===undefined);

                    });
                }
            }


        },
    }
</script>

<style scoped>

</style>
