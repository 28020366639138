<template>
  <v-app class="grey lighten-4">
    <Navbar
      :userProfile="userProfile"
      :loggedIn="loggedIn"
    />
    <v-main>
      <v-container fluid>
        <snack-bar/>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <span class="black--text">&copy; 2022 Visionborne</span>
    </v-footer>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex';
  import Navbar from '@/components/Navbar';
  import SnackBar from "@/components/utils/SnackBar";
  export default {
    name: 'app',
    components: {SnackBar, Navbar },

    computed: {
      ...mapGetters('user', {
        userProfile: 'userProfile',
        loggedIn: 'loggedIn'
      })
    },
  };
</script>

<style lang="scss">
  $color-pack: false;

  @import '~vuetify/src/styles/main.sass';

  body {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

</style>
