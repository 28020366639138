import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/user'
import snackbar from '@/store/utils/snackbar'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    snackbar
  }
});

export default store;