export const DICTIONARY = {
    language:"en",
    en: {

        components:{
            combochips:{

                create:"Press enter to create a tag",
                header:{
                    create:"Select or create tags",
                    search:"Select tags"
                }
            }
        },

        buttons:{

            actions:{
                add:"Add",
                delete:"Delete",
                update:"Edit",
                find:"Search",
                new:"New",
                reset:"Reset",
                close:"Cancel",
                save:"Confirm",
            },

            saveStoreFront:"Publish on test env",
            publishStoreFront:"Publish on prod env"



        },
        word: {
            videoclip: 'Catalog',
            storefront: 'Storefront'
        },

        text:{
            newVideoClip:"New item"
        },

        rules:{
            image:{
                format:"Valid format:{x} x {y}"
            }
        }
        ,
        message:{
            publish: {
                storefront: {
                    success: "The storefront has been published successfully"
                }
            },
            save: {
                storefront: {
                    success: "The storefront has been published successfully"
                },
                videoclip: {
                    update: "{name} item has been edited successfully",
                    add: "{name} item has been added successfully",
                }
            }
        },

        dialog:{
            storefront:{
                title:{
                    add_shelf:"Add shelf",
                    add_group:"Add group",
                    add_video:"Add items",
                    update_shelf:"Edit shelf",
                    update_group:"Edit group",
                    update_video:"Edit items",
                }
            }

        },

        confirm:{
            leave:{
                storefront:{
                    title:"Attention",
                    message:"Are you sure to leave without saving the changes?"
                }

            },
            save:{
                storefront:{
                    title:"Publish on test env",
                    message:"Your changes are going to be permanent. Do you want to proceed anyway?"
                }
            },
            refresh: {
                storefront: {
                    title:"You don't have the latest version",
                    message:"WARNING! The Storefront has just been updated. Do you want to see the new changes?"
                }
            },
            publish:{
                storefront:{
                    title:"Publish on prod env",
                    message:"Your changes are going to be permanent. Do you want to proceed anyway?"
                }
            },
            delete:{
                entity:{
                    shelf:{
                        title:"Remove shelf",
                        message:"You're removing the entire <b>{name}</b shelf from the storefront, including groups and items inside. Do you want to proceed anyway?"
                    },
                    group:{
                        title:"Remove group",
                        message:"You're removing the entire <b>{name}</b group from the storefront, including the items inside. Do you want to proceed anyway?"
                    },
                    video:{
                        title:"Remove item",
                        message:"You're removing <b>{name}</b item from the storefront. Do you want to proceed anyway?"
                    }
                },
                item:{
                    title:"Delete item",
                    message:"You're deleting <b>{name}</b item permanently. Do you want to proceed anyway?"
                }
            }
        }
    }
};
export default {
    DICTIONARY
}
