import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=e89828aa&scoped=true&"
import script from "./FormBuilder.vue?vue&type=script&lang=js&"
export * from "./FormBuilder.vue?vue&type=script&lang=js&"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=e89828aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e89828aa",
  null
  
)

export default component.exports