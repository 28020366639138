import firebase from './firebase';
import store from './store';


const checkAuthStatus = () => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          store.commit(`user/USER_DETAILS`, user);
          resolve(true);
        } else {
          store.commit(`user/LOGOUT`);
          resolve(false);
        }
        });
    } catch {
      reject('api failed')
    }
  });
}


export default{
  checkAuthStatus
}
