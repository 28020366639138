import {getVideoClipsTags} from "@/functions/videoClipFunctions"



export default {
    form:[
        {
            type:"textField",
            label:"Name",
            name:"filter.name",
            prop:"filter",
            col:6,
        },

        {
            type:"comboChips",
            label:"Tags",
            name:"filter.tags",
            headerText:'components.combochips.header.search',
            col:6,
            actions:{
                create:false
            },
            execFunc:async() => await getVideoClipsTags(),
        }
    ],

    validate: {},


}
