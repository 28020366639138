export default {
    MODE:"test",
    NAME_SPACE:'backoffice',
    FIREBASE_CONFIG:{
        // apiKey: "AIzaSyDc3K6qRNklgbGY8ZCGM4eoHJ4wRqeUClA",
        // authDomain: "facebrity-backoffice.firebaseapp.com",
        // projectId: "facebrity-backoffice",
        // storageBucket: "facebrity-backoffice.appspot.com",
        // messagingSenderId: "62623897524",
        // appId: "1:62623897524:web:f10e3b564bad228cc484e1"

        apiKey: "AIzaSyAiT1QAxdV_Ok2vAMCJwvPhvgmBgcPCmCo",
        authDomain: "facebrity-test.firebaseapp.com",
        projectId: "facebrity-test",
        storageBucket: "facebrity-test.appspot.com",
        messagingSenderId: "985568948572",
        appId: "1:985568948572:web:4b2d624307804b868d1199",
        measurementId: "G-8G60R2CV1P"

    }
}
