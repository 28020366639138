var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drop-zone",class:{
    'drag-over': _vm.dragOver,
    dragging: _vm.treeModel.isDragging(),
    'no-drop': _vm.treeModel.isDragging() && !_vm.canDrop
  },style:({
    ...(_vm.dragOver &&
      _vm.theme.primaryColor && { borderColor: _vm.theme.primaryColor }),
    ...(_vm.dragOver &&
      _vm.theme.dropZoneBgColor && { backgroundColor: _vm.theme.dropZoneBgColor })
  }),on:{"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)}}},[(_vm.options.dropZoneComponent)?_c(_vm.options.dropZoneComponent,{tag:"component",staticStyle:{"flex-grow":"1"},attrs:{"dragging":_vm.treeModel.isDragging(),"drag-over":_vm.dragOver}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }